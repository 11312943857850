import React, { Component, useEffect, useState } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import logo from './assets/img/logo/logo_dark.png';
import './index.css'
// import { renderRoutes } from 'react-router-config';
import './App.scss';

import withAuth from './services/withAuth';

import { GoogleOAuthProvider } from '@react-oauth/google';
const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Pages

/* let chat_data;
let user_data; */

function App(props) {

  const Login = React.lazy(() => import('./views/Login/Login'));
  const ResetPassword = React.lazy(() => import('./views/ResetPassword/ResetPassword'));
  const Activate = React.lazy(() => import('./views/Activate/Activate'));
  const ActivateGuest = React.lazy(() => import('./views/ActivateGuest/ActivateGuest'));
  const Register = React.lazy(() => import('./views/Register/Register'));
  const Joining = React.lazy(() => import('./views/Joining/Joining'));
  const Home = React.lazy(() => import('./views/Home/Home'));
  const Website = React.lazy(() => import('./views/Website/Website'));
  /*    componentDidMount() {
       user_data = localStorage.getItem('userData');
       if (user_data != null && typeof (user_data) != 'undefined') {
          chat_data = document.getElementById('hubspot-messages-iframe-container');
         setTimeout(() => {
           console.log(chat_data, 'sc+++++++=')
         }, 3000);
         console.log(chat_data, '++++++++++++==', user_data)
       }
     } */
  const [chatData, setChatData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading2, setLoading] = useState(true);

  useEffect(() => {
    // Simulación de la carga de datos
    setTimeout(() => {
      setChatData('chat data');
      setUserData('user data');
      setLoading(false);
    }, 2000);
  }, []);
  if (loading2) {
    return (
      <div className="loader">
        <img src={logo} alt="Logo" className="logo-loader" />
      </div>
    );; // Aquí puedes reemplazarlo con tu preloader
  } else {
    console.log("Holaaaaaaaaaa")
    return (
      <GoogleOAuthProvider clientId="636896653121-vo651c0hacsucp8ebafvgehs3e649hq7.apps.googleusercontent.com">
        <HashRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/login/:code?" name="Login" render={props => <Login {...props} />} />
              <Route exact path="/reset_password/:token" name="Reset Password" render={props => <ResetPassword {...props} />} />
              <Route exact path="/activate/:token" name="Account Activation" render={props => <Activate {...props} />} />
              <Route exact path="/activate_guest/:token" name="Guest Activation" render={props => <ActivateGuest {...props} />} />
              <Route exact path="/register" name="Register" render={props => <Register {...props} />} />
              <Route exact path="/joining/:code" name="Meeting" render={props => <Joining {...props} />} />
              <Route exact path="/" name="Website" render={props => <Website {...props} />} />
              
              <Route path="/" name="Home" component={withAuth(Home)} />
            </Switch>
          </React.Suspense>
        </HashRouter>
      </GoogleOAuthProvider>
    );
  }
}


export default App;
